<template>
  <div>
    <a-modal
      v-model="visibleModalBeasiswa"
      title="Tambah Beasiswa Peserta Didik"
      on-ok="handleOk"
      :destroyOnClose="true"
    >
      <a-form-model ref="ruleForm" :model="formBeasiswa" :rules="rules">
        <a-form-model-item ref="jenis" :colon="false" prop="jenis">
          <span slot="label">
            Jenis Beasiswa&nbsp;
            <a-tooltip
              title="Jenis beasiswa yang pernah diterima oleh peserta didik."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>

          <a-select size="large" v-model="formBeasiswa.jenis">
            <a-select-option
              v-for="item in jenisBeasiswaList"
              :key="item.title"
              :value="item.title"
              >{{ item.title }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <a-form-model-item ref="keterangan" :colon="false" prop="keterangan">
          <span slot="label">
            Keterangan&nbsp;
            <a-tooltip
              title="Keterangan terkait beasiswa yang pernah diterima oleh peserta didik. Misalnya dapat diisi dengan nama beasiswa, seperti Beasiswa Murid Berprestasi Tahun
2017, atau keterangan lain yang relevan."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input
            size="large"
            v-model="formBeasiswa.keterangan"
            @blur="
              () => {
                $refs.keterangan.onFieldBlur();
              }
            "
          >
          </a-input>
        </a-form-model-item>
        <a-form-model-item ref="tahun_mulai" :colon="false" prop="tahun_mulai">
          <span slot="label">
            Tahun Mulai&nbsp;
            <a-tooltip
              title="Tahun mulai diterimanya beasiswa oleh peserta didik."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number
            size="large"
            :max="9999"
            :min="1000"
            v-model="formBeasiswa.tahun_mulai"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item
          ref="tahun_selesai"
          :colon="false"
          prop="tahun_selesai"
        >
          <span slot="label">
            Tahun Selesai&nbsp;
            <a-tooltip
              title="Tahun selesai diterimanya beasiswa oleh peserta didik. Apabila beasiswa tersebut hanya diterima sekali dalam tahun yang sama, maka diisi sama seperti Tahun 2017, atau keterangan lain yang relevan."
            >
              <a-icon type="question-circle-o" />
            </a-tooltip>
          </span>
          <a-input-number
            size="large"
            :max="9999"
            :min="1000"
            v-model="formBeasiswa.tahun_selesai"
          ></a-input-number>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="handleVisibleModalBeasiswa"
          >Batal</a-button
        >
        <a-button
          key="submit"
          type="primary"
          :loading="loadingModalBeasiswa"
          @click="handleOkModalBeasiswa"
        >
          Tambah
        </a-button>
      </template>
    </a-modal>
    <a-table
      :columns="dataColumns"
      :data-source="dataTable"
      :pagination="{ pageSize: 30, hideOnSinglePage: true }"
      bordered
      size="large"
    >
      <template v-if="!isAdmin" slot="title">
        <a-button
          @click="handleVisibleModalBeasiswa"
          size="large"
          type="default"
          class="btn btn-outline-primary rounded my-2"
          >Tambah Beasiswa</a-button
        >
      </template>
      <span slot="aksi" slot-scope="text, record">
        <a-button
          @click="deleteRow(record.key)"
          type="default"
          size="large"
          class="btn btn-outline-danger rounded"
          >Hapus</a-button
        >
      </span>
    </a-table>
  </div>
</template>

<script>
import { jenisBeasiswaList } from '@/helpers/listsPpdbSelect'
const column = [
  {
    dataIndex: 'jenis',
    key: 'jenis',
    title: 'Jenis',
    scopedSlots: { customRender: 'jenis' },
    align: 'left',
  },
  {
    dataIndex: 'keterangan',
    key: 'keterangan',
    title: 'Keterangan',
    scopedSlots: { customRender: 'keterangan' },
    align: 'left',
  },
  {
    dataIndex: 'tahun_mulai',
    key: 'tahun_mulai',
    title: 'Tahun Mulai',
    scopedSlots: { customRender: 'tahun_mulai' },
    align: 'left',
  },
  {
    dataIndex: 'tahun_selesai',
    key: 'tahun_selesai',
    title: 'Tahun Selesai',
    scopedSlots: { customRender: 'tahun_selesai' },
    align: 'left',
  },
  {
    dataIndex: 'aksi',
    key: 'aksi',
    title: 'Aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]
const rules = {
  jenis: [
    { required: true, message: 'Jenis Beasiswa wajib diisi!', trigger: 'change' },
  ],
  keterangan: [
    { required: true, message: 'Keterangan wajib diisi!', trigger: 'blur' },
  ],
  tahun_mulai: [
    {
      required: true,
      validator: (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Tahun Mulai wajib diisi!'))
        }
        if (!Number.isInteger(value)) {
          callback(new Error('Tahun Mulai harus angka!'))
        } else {
          callback()
        }
      },
      trigger: 'blur',
    },
    // { len: 4, message: 'Tahun Mulai harus 4 digit!', trigger: 'change' },
  ],
  tahun_selesai: [
    {
      required: true,
      validator: (rule, value, callback) => {
        if (!value) {
          return callback(new Error('Tahun Selesai wajib diisi!'))
        }
        if (!Number.isInteger(value)) {
          callback(new Error('Tahun Selesai harus angka!'))
        } else {
          callback()
        }
      },
      trigger: 'blur',
    },
    // { len: 4, message: 'Tahun Selesai harus 4 digit!', trigger: 'change' },
  ],
}
export default {
  props: {
    dataTable: {
      type: Array,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      column,
      jenisBeasiswaList,
      rules,
      visibleModalBeasiswa: false,
      loadingModalBeasiswa: false,
      formBeasiswa: {
        jenis: '',
        keterangan: '',
        tahun_mulai: '',
        tahun_selesai: '',
      },
    }
  },
  computed: {
    dataColumns() {
      if (this.isAdmin) {
        return this.column.filter(row => row.key !== 'aksi')
      } else {
        return this.column
      }
    },
  },
  methods: {
    handleVisibleModalBeasiswa() {
      this.visibleModalBeasiswa = !this.visibleModalBeasiswa
    },
    handleOkModalBeasiswa() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('added', this.formBeasiswa)
          this.handleVisibleModalBeasiswa()
          this.formBeasiswa = {
            jenis: '',
            keterangan: '',
            tahun_mulai: '',
            tahun_selesai: '',
          }
        } else {
          this.$notification.error({
            message: 'Maaf',
            description: 'Ada data wajib yang belum terisi.',
          })
          console.log('error submit!!')
          return false
        }
      })
    },
    deleteRow(value) {
      this.dataTable.splice(this.dataTable.findIndex(el => el.key === value), 1)
    },
  },
}
</script>

<style>
</style>
